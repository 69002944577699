<template>
  <div class="content">
    <Navbar/>
    <div class="about">
      <p>
        <span class="painted">cheqr</span> allows community-driven workshops, makerspaces &amp; fab labs to:
      </p>
      <ul>
        <li>Quickly verify active memberships</li>
        <li>Organize member-associated data</li>
        <li>Manage &amp; track course participation</li>
        <li>Authorize the use of dangerous or expensive equipment</li>
        <li>Generate templates for laser-cut membership badges</li>
      </ul>
    </div>
  </div>
</template>

<style scoped>
.content {
    background: rgb(245, 240, 238);
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.about {
  text-justify: left;
  max-width: 450px;
  color: rgba(14,7,7,1);
  padding: 1rem;
}
</style>

<script>
import Navbar from '@/components/Navbar.vue'

export default {
  name: 'About',
  components: {
    Navbar
  }
}
</script>